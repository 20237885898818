import { createRouter, createWebHistory } from 'vue-router';

const routes = [
  {
    path: '/devices/:deviceId',
    name: 'DeviceDetail',
    component: () => import('../components/DeviceDetail.vue'),
    props: true
  },

  {
    path: '/device_infos/:deviceId',
    name: 'DeviceInfo',
    component: () => import('../components/DeviceInfo.vue'),
    props: true,
    meta: { requiresAuth: true }
  },

  {
    path: '/connections/:lineId',
    name: 'LineInfo',
    component: () => import('../components/LineInfo.vue'),
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/manufacturermanage',
    name: 'ManufacturerManage',
    component: () => import('../components/ManufacturerManage.vue'),
    props: true
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../components/UseLogin.vue'),
    props: true,
    meta: {
      requiresAuth: false  
    }
  },

];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach((to, from, next) => {
  const isLoggedIn = !!localStorage.getItem('jwt');  

  if (!isLoggedIn && to.meta.requiresAuth) {
   
    const redirectPath = encodeURIComponent(to.fullPath);  
    next({ name: 'Login', query: { redirect: redirectPath }});
  } else if (isLoggedIn && to.name === 'Login') {
    
    const defaultPath = encodeURIComponent('/');
    next(decodeURIComponent(defaultPath));
  } else {
    next();  
  }
});

export default router;
