import { createStore } from 'vuex';
import axios from 'axios';

axios.defaults.baseURL = 'https://hbtv.top';

export default createStore({
  state: {
    accessToken: null,
    refreshToken: null,
  },
  mutations: {
    SET_TOKENS(state, { access, refresh }) {
      state.accessToken = access;
      state.refreshToken = refresh;
    },
  },
  actions: {
    async login({ commit }, credentials) {
      try {
        const response = await axios.post('/XgMT-api/token/', credentials);
        commit('SET_TOKENS', response.data);
        axios.defaults.headers.common['Authorization'] = `Bearer ${response.data.access}`;
      } catch (error) {
        throw new Error('Authentication failed');
      }
    },
  }
});