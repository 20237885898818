import { createApp } from 'vue'
import App from './App.vue'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import router from './router';
import axios from 'axios'
import './assets/global.css'
import store from './store';
axios.defaults.withCredentials = true;
const app = createApp(App);
app.use(ElementPlus);
app.use(router);
app.use(store);
app.mount('#app')
